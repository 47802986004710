import React from 'react';
import { StandardEditorProps } from '@grafana/data';
import { LegacyForms } from '@grafana/ui';

interface Settings {
    axisObject: string;
    axisName: string;
}

export const AxisControl: React.FC<StandardEditorProps<string, Settings>> = ({ item, value, onChange, context }) => {

    const onAxisBlur = ({ target }: any) => {
        let values: string[] = target.value.split(',');
        values = values.map(v => v.trim()).filter(v => v !== '');
    
        if (item.settings)
            context.options[item.settings.axisObject] = values;

        if (values.length === 0) {
            value = '';
        } 

        onChange(value);
      };

    const onAxisChanged = ({ target }: any) => { 
        onChange(target.value);
      };
     
    return (
       <LegacyForms.FormField
            label={item.settings?.axisName + " all values" }
            labelWidth={9}
            inputWidth={11}
            type="text"
            onChange={onAxisChanged}
            onBlur={onAxisBlur}
            value={value}
          />
    );
}
