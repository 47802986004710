export interface HeatmapAdvancedOptions {
  cellWidth: string;
  cellHeight: string;

  xAxisTag: string;
  xScript: string;
  xScriptPlaceholder: string;
  yAxisTag: string;
  yScript: string;
  yScriptPlaceholder: string;

  valueName: string;

  xaxis: string[];
  xaxisString: string;

  yaxis: string[];
  yaxisString: string;

  groups: { [key: string]: string[] };
  groupsString: string;
  groupsVariable: string;
  showGroupsOnly: boolean;
  xFontSize: string;
  yFontSize: string;
  yPosition: string;

  xValueMapping: any[];
  calculation: string;

  divideByTag: string;
  colors: any[];

  borderColor: string;
  xAxisRotate: string;
  labelSpacing: string;
  valueType: string;
}

export const defaults: HeatmapAdvancedOptions = {
  cellWidth: '80px',
  cellHeight: '14px',

  xAxisTag: '',
  xScript: '',
  xScriptPlaceholder: '',
  yAxisTag: '',
  yScript: '',
  yScriptPlaceholder: '',
  valueName: 'FloatValue',

  xaxis: [],
  groups: {},
  xaxisString: '',
  yaxis: [],
  yaxisString: '',
  groupsString: '{}',
  groupsVariable: '',
  showGroupsOnly: false,
  xFontSize: '11px',
  yFontSize: '12px',
  yPosition: 'left',

  xValueMapping: [{ Key: 'null', Value: '0' }],
  calculation: 'percent',
  divideByTag: '',
  colors: [
    { min: '0', max: '0', value: '#FFFFFF' },
    { min: '0', max: '10', value: '#DAFFD5' },
    { min: '10', max: '20', value: '#C1FFB7' },
    { min: '20', max: '30', value: '#C1FFB7' },
    { min: '30', max: '40', value: '#65F44F' },
    { min: '40', max: '50', value: '#6DFC57' },
    { min: '50', max: '60', value: '#59E843' },
    { min: '60', max: '70', value: '#3AC924' },
    { min: '70', max: '80', value: '#3BCA25' },
    { min: '80', max: '90', value: '#159401' },
    { min: '90', max: '101', value: '#0F6601' },
  ],
  borderColor: '#A7F79A',
  xAxisRotate: '',
  labelSpacing: '30',
  valueType: '',
};
