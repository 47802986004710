import React from 'react';
import { StandardEditorProps } from '@grafana/data';

export class Color { 
    min: string = '';
    max: string = '';
    value: string = '';
}

export const ColorMapping: React.FC<StandardEditorProps<Color[]>> = ({ item, value, onChange, context }) => {

    
  const addColor = (e: any) => {
    const arr = [...value];

    arr.push({ min: '101', max: '1000', value: '#159401' }); 
    onChange(arr);
  };
  
  const removeColor = (color: any) => {
      onChange(value.filter(i => i['min'] !== color['min'] && i['max'] !== color['max']));
  }; 
 
  const onMinColorRangeChanged = ({ target }: any) => {
    const copy = [...value];
    copy[parseInt(target.name, 10)]['min'] = target.value;

    onChange(copy);
  }; 

  const onMaxColorRangeChanged = ({ target }: any) => {
    const copy = [...value];
    copy[parseInt(target.name, 10)]['max'] = target.value;

    onChange(copy);
  };

  const onColorValueChanged = ({ target }: any) => {
    const copy = [...value];
    copy[parseInt(target.name, 10)]['value'] = target.value;

    onChange(copy);
  };

    return (
       <div>
           {value.map((color, index) => (
            <div className="gf-form" key={color.value + index}>
              <span className="gf-form-label">
                <i className="fa fa-remove pointer" onClick={() => removeColor(color)}></i>
              </span>
              <input
                type="text"
                name={value.lastIndexOf(color) + ''}
                value={color['min'] || ''}
                placeholder="value"
                onChange={onMinColorRangeChanged}
                className="gf-form-input max-width-6"
              />
              <span className="gf-form-label">
                <i className="fa fa-arrow-right"></i>
              </span>
              <input
                type="text"
                name={value.lastIndexOf(color) + ''}
                value={color['max'] || ''}
                placeholder="value"
                onChange={onMaxColorRangeChanged}
                className="gf-form-input max-width-6"
              />
              <span className="gf-form-label">
                <i className="fa fa-arrow-right"></i>
              </span>
              <input
                type="text"
                name={value.lastIndexOf(color) + ''}
                placeholder="text"
                onChange={onColorValueChanged}
                value={color['value'] || ''}
                className="gf-form-input max-width-8"
              />
            </div>
          ))}
          <div className="gf-form-button-row">
            <button className="btn btn-inverse" onClick={addColor}>
              <i className="fa fa-plus"></i>
              Add color range
            </button>
          </div>
       </div>
    );
}
