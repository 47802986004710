import { PanelPlugin } from '@grafana/data';
import { HeatmapAdvancedOptions, defaults } from './types';
import { HeatmapAdvancedPanel } from './HeatmapAdvancedPanel';
import { AxisControl } from 'controls/AxisControl';
import { ColorMapping } from 'controls/ColorMapping';
import { ValueMapper } from 'controls/ValueMapper';

export const plugin = new PanelPlugin<HeatmapAdvancedOptions>(HeatmapAdvancedPanel).setPanelOptions(builder => {
    return builder
        .addTextInput({
            name: 'Cell height',
            path: 'cellHeight',
            defaultValue: defaults.cellHeight
        })
        .addTextInput({
            name: 'Cell width',
            path: 'cellWidth',
            defaultValue: defaults.cellWidth
        })
        .addColorPicker({
            name: 'Border color',
            path: 'borderColor',
            defaultValue: defaults.borderColor
        })
        .addTextInput({
            name: 'Label spacing',
            path: 'labelSpacing',
            defaultValue: defaults.labelSpacing
        })
        .addTextInput({
            name: 'X axis rotation (degrees)',
            path: 'xAxisRotate',
            defaultValue: defaults.xAxisRotate
        })
        .addTextInput({
            name: 'Y axis tag',
            path: 'yAxisTag',
            defaultValue: defaults.yAxisTag,
            category: ['Y axis']
        })
        .addTextInput({
            name: 'Font size',
            path: 'yFontSize',
            defaultValue: defaults.yFontSize,
            category: ['Y axis']
        })
        .addSelect({
            name: 'Position',
            path: 'yPosition',
            defaultValue: defaults.yFontSize,
            category: ['Y axis'],
            settings: {
                options: [
                  { value: 'left', label: 'Left' },
                  { value: 'right', label: 'Right' }
                 ]
              },
        }) 
        .addTextInput({
            name: 'Transform script',
            path: 'yScriptPlaceholder',
            defaultValue: defaults.yScriptPlaceholder,
            category: ['Y axis']
        }) 
        // .addTextInput({
        //     name: 'Y groups (json)',
        //     path: 'yFontSize',
        //     defaultValue: defaults.groupsString,
        //     category: ['Y axis']
        // })
        .addTextInput({
            name: 'Groups variable',
            path: 'groupsVariable',
            defaultValue: defaults.groupsVariable,
            category: ['Y axis']
        }) 
        .addCustomEditor({
            name: 'Y all values',
            path: 'yaxisString',
            defaultValue: defaults.yaxisString,
            category: ['Y axis'],
            id: '1',
            editor: AxisControl,
            settings: { 
                axisName: 'Y',
                axisObject: 'yaxis'
            }
        }) 
        .addBooleanSwitch({
            name: 'Show groups only',
            path: 'showGroupsOnly',
            defaultValue: defaults.showGroupsOnly,
            category: ['Y axis']
        })
        .addTextInput({
            name: 'X axis tag',
            path: 'xAxisTag',
            defaultValue: defaults.xAxisTag,
            category: ['X axis']
        })
        .addTextInput({   
            name: 'Font size',
            path: 'xFontSize',
            defaultValue: defaults.xFontSize,
            category: ['X axis']
        })
        // x script placeholder
        .addCustomEditor({ 
            name: 'X all values',
            path: 'xAxisString',
            defaultValue: defaults.xaxisString,
            category: ['X axis'],
            id: '2',
            editor: AxisControl,
            settings: { 
                axisName: 'X',
                axisObject: 'xaxis'
            }
        })
        .addTextInput({
            name: 'Value name',
            path: 'valueName',
            defaultValue: defaults.valueName,
            category: ['Value']
        })
        .addTextInput({
            name: 'Value postfix',
            path: 'valuePostfix',
            defaultValue: defaults.valueType,
            category: ['Value']
        })
        .addSelect({
            name: 'Calculation',
            path: 'calculation',
            defaultValue: defaults.calculation,
            category: ['Value'],
            settings: { 
                options: [
                    { value: 'percent', label: 'Percentage' },
                    { value: 'real', label: 'Real value' }
                ]
            }
        }) 
        .addTextInput({
            name: 'Divide by count of',
            path: 'divideByTag',
            defaultValue: defaults.divideByTag,
            category: ['Value']
        })  
        .addCustomEditor({
            name: 'Colors',
            path: 'colors',
            id: '5',
            editor: ColorMapping,
            category: ['Colors']
        }) 
        .addCustomEditor({
            name: 'Mapping',
            path: 'xValueMapping',
            id: '6',
            editor: ValueMapper, 
            category: ['Value mapping']
        })

}) 

