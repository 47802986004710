import React from 'react';
import { StandardEditorProps } from '@grafana/data';

export class ValueMap {
  Key: string = '';
  Value: string = '';
}

export const ValueMapper: React.FC<StandardEditorProps<ValueMap[]>> = ({ item, value, onChange, context }) => {
  const addMapping = (e: any) => {
    const arr = [...value];

    arr.push({ Key: 'null', Value: '0' });
    onChange(arr);
  };

  const removeMapping = (map: any) => {
    onChange(value.filter((i) => i['Key'] !== map['Key']));
  };

  const onValueMappingKeyChanged = ({ target }: any) => {
    const copy = [...value];
    copy[parseInt(target.name, 10)]['Key'] = target.value;

    onChange(copy);
  };

  const onValueMappingValueChanged = ({ target }: any) => {
    const copy = [...value];
    copy[parseInt(target.name, 10)]['Value'] = target.value;

    onChange(copy);
  };

  return (
    <div>
      {value.map((vm) => (
        <div className="gf-form">
          <span className="gf-form-label">
            <i className="fa fa-remove pointer" onClick={() => removeMapping(vm)}></i>
          </span>
          <input
            type="text"
            name={value.lastIndexOf(vm) + ''}
            value={vm['Key'] || ''}
            placeholder="value"
            onChange={onValueMappingKeyChanged}
            className="gf-form-input max-width-6"
          />
          <span className="gf-form-label">
            <i className="fa fa-arrow-right"></i>
          </span>
          <input
            type="text"
            name={value.lastIndexOf(vm) + ''}
            placeholder="text"
            onChange={onValueMappingValueChanged}
            value={vm['Value'] || ''}
            className="gf-form-input max-width-8"
          />
        </div>
      ))}
      <div className="gf-form-button-row">
        <button className="btn btn-inverse" onClick={addMapping}>
          <i className="fa fa-plus"></i>
          Add a value mapping
        </button>
      </div>
    </div>
  );
};
