import React, { PureComponent } from 'react';
import { PanelProps } from '@grafana/data';
import { HeatmapAdvancedOptions } from 'types';
// import appEvents from 'grafana/app/core/app_events';
import ReactTooltip from 'react-tooltip';
import { getDataSourceSrv } from '@grafana/runtime';

interface Props extends PanelProps<HeatmapAdvancedOptions> {}
 
export class HeatmapAdvancedPanel extends PureComponent<Props> {
  minValue: number = Number.MAX_SAFE_INTEGER;
  maxValue = 0; 

  constructor(props: any) {
    super(props);

    if (this.props.options.groupsVariable) {
      const api: any = getDataSourceSrv();

      const vars: any[] = api['templateSrv'].variables;
      const deparments = vars.find(v => v.name === this.props.options.groupsVariable);

      if (deparments) {
        this.props.options.groupsString = deparments.current.value;
        this.props.options.groups = JSON.parse(deparments.current.value);
      }
    }
  }

  lightenDarkenColor = (col: string, amt: number) => {
    const num = parseInt(col, 16);
    const r = (num >> 16) - amt;
    const b = ((num >> 8) & 0x00ff) - amt;
    const g = (num & 0x0000ff) - amt;
    const newColor = g | (b << 8) | (r << 16);
    return '#' + newColor.toString(16);
  };

  getColor(numbers: number) {
    const percentage = (numbers / this.maxValue) * 100;

    for (const color of this.props.options.colors) {
      const min = parseInt(color['min'], 10);
      const max = parseInt(color['max'], 10);
      const value = color['value'];

      if (percentage === 0 && min === 0 && max === 0) {
        return value;
      }

      if (percentage >= min && percentage < max) {
        return value;
      }
    }
  } 


  render() {
    const { options, data, timeRange } = this.props;

    // let values: string[] = options.yaxisString.split(',');
    // values = values.map(v => v.trim()).filter(v => v !== '');

    // this.props.onOptionsChange({ ...this.props.options, yaxis: values });
    // if (values.length === 0) {
    //   this.props.onOptionsChange({ ...this.props.options, yaxisString: '' });
    // }

    // values = options.xaxisString.split(',');
    // values = values.map(v => v.trim()).filter(v => v !== '');

    // this.props.onOptionsChange({ ...this.props.options, xaxis: values });
    // if (values.length === 0) {
    //   this.props.onOptionsChange({ ...this.props.options, xaxisString: '' });
    // }

    const groupedData: { [key: string]: { [key: string]: number } } = {};
    let xAllValues: string[] = [];
    if (options.xaxisString) {
      xAllValues = [...options.xaxis];
    }

    // @ts-ignore
    const differentSeries = {};

    this.maxValue = 0;

    for (const serie of data.series) {
      const xtagIndex = serie.fields.findIndex(f => f.name === options.xAxisTag);
      const ytagIndex = serie.fields.findIndex(f => f.name === options.yAxisTag);
      const valueIndex = serie.fields.findIndex(f => f.name === options.valueName);
      if (xtagIndex === -1 || ytagIndex === -1 || valueIndex === -1) {
        console.log(`serie does not contain tag xtagIndex: ${xtagIndex}, ytagIndex: ${ytagIndex}, valueIndex: ${valueIndex}`);
        continue;
      }

      const points: any[] = [];
      const executeScripts = `for (let i = 0; i < serie.fields[0].values.length; ++i) {
        let xtag = serie.fields[xtagIndex].values.get(i);
        let ytag = serie.fields[ytagIndex].values.get(i);
        const data = serie.fields[valueIndex].values.get(i);

        if (options.divideByTag) {
          const serieTagIndex = serie.fields.findIndex(f => f.name === options.divideByTag);
          if (serieTagIndex > -1) {
            differentSeries[serie.fields[serieTagIndex].values.get(i)] = '';
          }
        }

        let value = '';
        ${options.xScript &&
          `value = xtag + '';
          xtag = ${options.xScript};`}

        ${options.yScript &&
          `value = ytag + '';
          ytag = ${options.yScript};`}

          points.push({ 'xtag': xtag, 'ytag': ytag, value: data});
      }`;

      /* tslint:disable-next-line */
      eval(executeScripts);

      for (const item of points) {
        const xtag = item['xtag'] + '';
        const ytag = item['ytag'] + '';
        const value = item['value'];

        let yValue = ytag;
        if (Object.keys(options.groups).length > 0) {
          for (const key of Object.keys(options.groups)) {
            if (options.groups[key].lastIndexOf(ytag) !== -1) {
              yValue = key;
            }
          }

          // if no map found, skip
          if (options.showGroupsOnly && yValue === ytag) {
            continue;
          }
        }

        if (!groupedData[yValue]) {
          groupedData[yValue] = {};
        }

        // if no list for y axis is given in configuration, fill the y axis with the real values
        if (xAllValues.findIndex(i => i === xtag) === -1) {
          if (!options.xaxisString) {
            xAllValues.push(xtag);
          } else {
            continue;
          }
        }

        if (!groupedData[yValue][xtag]) {
          groupedData[yValue][xtag] = value;
        } else {
          groupedData[yValue][xtag] += value;
        }

        if (groupedData[yValue][xtag] < this.minValue) {
          this.minValue = groupedData[yValue][xtag];
        }
        if (groupedData[yValue][xtag] > this.maxValue) {
          this.maxValue = Math.floor(groupedData[yValue][xtag]);
        }
      }
    }

    const keys = options.yaxisString ? options.yaxis : Object.keys(groupedData);

    let days = timeRange.to.diff(timeRange.from, 'd');
    if (days === 0) {
      days = 1;
    }

    return (
      <div>
        <table style={{ margin: 'auto', tableLayout: 'fixed', width: parseInt(options.cellWidth, 10) * xAllValues.length + 'px' }}>
          <tbody>
            {keys.map(key => (
              <tr style={{ lineHeight: options.cellHeight + 'px' }} >
                <td style={{ width: options.cellWidth }}>
                  {options.yPosition === 'left' && <span style={{ fontSize: options.yFontSize, marginRight: '7px', marginLeft: '7px' }}>{key}</span>}
                </td>
                {xAllValues.map(value => {
                  if (!groupedData[key]) { 
                    groupedData[key] = { value: 0 };
                  }

                  let realValue = groupedData[key][value];
                  realValue = Math.floor(realValue);

                  let percentageValue = realValue;
                  if (options.calculation === 'percent') {
                    percentageValue *= 100;
                  }

                  if (options.divideByTag) {
                    percentageValue /= days * (Object.keys(differentSeries).length || 1);
                  }

                  percentageValue = Math.floor(percentageValue);

                  return (
                    <td
                      style={{
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: options.borderColor,
                        backgroundColor: this.getColor(realValue) || '#FFFFFF',
                      }}
                      data-tip
                      data-for={`${key}${value}`}
                      key={`${key}${value}`}
                    >
                      <ReactTooltip id={`${key}${value}`}>
                        {/* <div>{(percentageValue || 0) + (options.calculation === 'percent' ? '%' : '')}</div> */}
                        <div>
                          {realValue || 0}
                          {options.valueType}
                        </div>
                      </ReactTooltip>
                    </td>
                  );
                })}

                {options.yPosition === 'right' && (
                  <td style={{ width: options.cellWidth }}>
                    <span style={{ fontSize: options.yFontSize, marginRight: '7px', marginLeft: '7px' }}>{key}</span>{' '}
                  </td>
                )}
              </tr>
            ))}

            {options.xAxisRotate && (
              <tr>
                <td style={{ height: options.labelSpacing + 'px' }}></td>
              </tr>
            )}
            <tr>
              <td></td>
              {xAllValues.map(val => {
                const item = options.xValueMapping.filter(i => i['Key'] === val)[0];
                return (
                  <td
                    style={{
                      width: options.cellWidth,
                      textAlign: 'center',
                      fontSize: options.xFontSize,
                      transform: options.xAxisRotate ? `rotate(${options.xAxisRotate}deg)` : '',
                      transformOrigin: 'center',
                    }}
                  >
                    {item ? item['Value'] : val}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
